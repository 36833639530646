import { createReducer, on } from '@ngrx/store';
import { IAuth, IAuthFeatureState } from './auth.states';
import {
  loadAuth,
  login,
  loginFailed,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  setIsFirstTime,
  updateRoles,
  updateToken,
} from './auth.action';

const initialState: IAuthFeatureState = {
  user: null,
  error: null,
  isAuthenticate: false,
  isLoading: false,
  isSuccess: false,
};

export const AuthReducer = createReducer(
  initialState,

  //login
  on(login, (state, action) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),

  //login success
  on(loginSuccess, (state, action) => {
    return {
      ...state,
      user: action.currentUser,
      isLoading: false,
      isAuthenticate: true,
      error: null,
    };
  }),
  //load auth from stored
  on(loadAuth, (state, action) => {
    return {
      ...state,
      user: action.currentUser,
      isLoading: false,
      isAuthenticate: action.currentUser ? true : false,
      error: null,
      isSuccess: true,
    };
  }),
  //Update token
  on(updateToken, (state, action) => {
    if (state.user) {
      return {
        ...state,
        user: {
          ...state.user,
          accessToken: action.token.accessToken,
          refreshToken: action.token.refreshToken,
        },
      };
    } else {
      return state;
    }
  }),
  //Update roles
  on(updateRoles, (state, action) => {
    if (state.user) {
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.roles,
        },
      };
    } else {
      return state;
    }
  }),
  //login failed
  on(loginFailed, (state, action) => {
    return {
      ...state,
      user: null,
      isLoading: false,
      isAuthenticate: false,
      error: action.error,
    };
  }),
  // Logout success
  on(logoutSuccess, (state) => ({
    ...state,
    user: null,
    error: null,
    isAuthenticate: false,
  })),
  // Logout failure
  on(logoutFailure, (state, action) => ({
    ...state,
    error: action.error,
  })),
  // set isFirstTime
  on(setIsFirstTime, (state, action) => {
    if (state.user) {
      return {
        ...state,
        user: {
          ...state.user,
          isFirstTime: action.isFirstTime,
        },
      };
    } else {
      return state;
    }
  })
);
