import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IUserFeatureState } from './user.states';
import { initUser, setUserInfo, setUserIsInitMerchant } from './user.actions';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectUser } from './user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{ user: IUserFeatureState }>
  ) {}

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initUser),
      switchMap(() => {
        const storedProfile = localStorage.getItem('profile');
        if (storedProfile) {
          const profile = JSON.parse(storedProfile);
          return of(setUserInfo({ user: profile }));
        }
        return of(setUserInfo({ user: null }));
      })
    )
  );

  storedProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUserIsInitMerchant, setUserInfo),
        withLatestFrom(this.store.select(selectUser)),
        tap(([action, profile]) => {
          if (profile) {
            localStorage.setItem('profile', JSON.stringify(profile));
          } else {
            localStorage.removeItem('profile');
          }
        })
      ),
    { dispatch: false }
  );
}
