import { createReducer, on } from '@ngrx/store';
import { IUserFeatureState } from './user.states';
import { setUserInfo, setUserIsInitMerchant } from './user.actions';

const initialState: IUserFeatureState = {
  profile: null,
  isSuccess: false,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(setUserInfo, (state, action) => {
    return {
      ...state,
      profile: action.user,
      isSuccess: true,
    };
  }),
  on(setUserIsInitMerchant, (state, action) => {
    if (state?.profile) {
      return {
        ...state,
        profile: {
          ...state.profile,
          isInitMerchant: action.isInitMerchant,
        },
      };
    }
    return state; // Return the original state if profile is null
  })
);
