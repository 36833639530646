import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';

import { AppState, IToken } from '../shared/interfaces/utils.interface';
import { selectGetToken } from '../_store/auth/auth.selectors';
import { logoutSuccess, updateToken } from '../_store/auth/auth.action';
import { setUserInfo } from '../_store/user/user.actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>
  ) {}
  token!: IToken;
  refreshUrl = '/auth/refresh';
  private refreshingToken = false; // Flag to track if token refresh is in progress

  // url which wants to cache data
  private urlToCache = [];

  // Map of caching data key: value pair key === url / value === data
  private responseCache = new Map<
    string,
    { response: HttpEvent<any>; timestamp: number }
  >();
  // timestamp to set cache life
  private cacheExpirationTime = 1800000; // 30 minutes in milliseconds

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const cache = this.responseCache.get(request.urlWithParams);
    // if cache return data which already have
    if (cache && this.isCacheValid(cache.timestamp)) {
      return of(cache.response);
    }

    // use store selector of Ngrx to get token in stored and sent it with header
    return this.store.select(selectGetToken).pipe(
      take(1),
      mergeMap((token: IToken) => {
        if (token && !request.url.includes(this.refreshUrl)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token.accessToken}`,
            },
          });
        }

        if (!this.canCache(request)) {
          return next
            .handle(request)
            .pipe(
              catchError((error: HttpErrorResponse) =>
                this.errorAuthHandle(error, request, next)
              )
            );
        } else {
          return next.handle(request).pipe(
            tap((res) => {
              this.responseCache.set(request.urlWithParams, {
                response: res,
                timestamp: Date.now(),
              });
            }),
            catchError((error: HttpErrorResponse) =>
              this.errorAuthHandle(error, request, next)
            )
          );
        }
      })
    );
  }

  private canCache(request: HttpRequest<unknown>) {
    return this.urlToCache.some((keyword) =>
      request.urlWithParams.includes(keyword)
    );
  }

  private isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < this.cacheExpirationTime;
  }

  private errorAuthHandle(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ) {
    if (
      (error.status === 401 || error.error.statusCode === 401) &&
      error.error.statusMessage === 'JWT Expired.'
    ) {
      // return method to get new token

      return this.refreshTokenMethod(request, next);
    } else if (
      error.error instanceof Blob &&
      error.statusText === 'Unauthorized'
    ) {
      return this.refreshTokenMethod(request, next);
    } else if (
      (error.status === 401 || error.error.statusCode === 401) &&
      (error.error.statusMessage === 'AccessToken is invalid.' ||
        error.error.statusMessage === 'RefreshToken is invalid.')
    ) {
      this.forceLogout();

      return EMPTY;
    } else if (
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Forbidden resource') ||
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Forbidden') ||
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Access Denied')
    ) {
      this.forceLogout();
      return EMPTY;
    } else {
      return throwError((): any => error);
    }
  }

  private refreshTokenMethod(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true; // Set flag to indicate token refresh is in progress
      this.authService.notifyRefreshTokenUpdated(null);

      return this.authService.RefreshToken().pipe(
        switchMap((res: any) => {
          const token = {
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          };
          this.store.dispatch(updateToken({ token: token }));
          this.authService.notifyRefreshTokenUpdated(res.data.accessToken);

          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + res.data.accessToken },
          });

          if (!this.canCache(request)) {
            return next.handle(request);
          } else {
            return next.handle(request).pipe(
              tap((res) => {
                this.responseCache.set(request.urlWithParams, {
                  response: res,
                  timestamp: Date.now(),
                });
              })
            );
          }
        }),
        catchError((error) => {
          // Reset the flag if there's an error during token refresh

          this.refreshingToken = false;

          if (
            ((error.status === 401 || error.error.statusCode === 401) &&
              error.error.statusMessage === 'RefreshToken is invalid.') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Access Denied') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Forbidden resource') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Forbidden')
          ) {
            this.forceLogout();

            return EMPTY;
          } else {
            return throwError(() => error);
          }
        }),
        finalize(() => {
          this.refreshingToken = false;
        })
      );
    } else {
      // If a refresh token request is already in progress, wait for it to complete

      return this.authService.refreshTokenObservable.pipe(
        filter((token) => {
          return token !== null;
        }),
        switchMap((): Observable<HttpEvent<any>> => {
          return this.intercept(request, next);
        })
      );
    }
  }

  forceLogout() {
    // function force user to go to login
    this.store.dispatch(logoutSuccess());
    this.store.dispatch(setUserInfo({ user: null }));
    window.location.replace('/');
  }
}
