import { AdminRole, MerchantRole } from 'src/app/shared/enums/roles';

export interface IAuth {
  _id: string;
  accessToken: string | null;
  refreshToken: string | null;
  email: string;
  roles: AdminRole | MerchantRole;
  isFirstTime: boolean;
}

export interface IAuthFeatureState {
  user: IAuth | null;
  isLoading: boolean;
  error: string | null;
  isAuthenticate: boolean;
  isSuccess: boolean;
}

export const authFeatureKey = 'auth';
