import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../shared/interfaces/utils.interface';
import { Observable, Subscription } from 'rxjs';
import { AdminRole, MerchantRole } from '../shared/enums/roles';
import { selectAuthRole } from '../_store/auth/auth.selectors';
import { Router } from '@angular/router';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  roles: any;
  roles$!: Observable<AdminRole | MerchantRole | null>;
  subscription!: Subscription;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private helperService: HelperService
  ) {
    this.roles$ = store.select(selectAuthRole);
  }

  ngOnInit(): void {
    this.subscription = this.roles$.subscribe((data) => (this.roles = data));
  }

  onNavigate() {
    if (this.helperService.isMerchant(this.roles)) {
      this.router.navigate(['/merchant']);
    } else {
      this.router.navigate(['/admin']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
