<div class="flex flex-col w-screen min-h-screen">
  <div class="flex-grow flex flex-col items-center justify-center p-10">
    <h1 class="text-4xl font-medium text-center text-main-blue">
      {{ "Page Not Found" }}
    </h1>
    <p class="text-xl opacity-50 mt-3 text-center">
      {{ "We can't seem to find the page you're looking for" }}
    </p>
    <div class="mt-10">
      <button
        (click)="onNavigate()"
        class="flex items-center justify-center gap-1 text-white bg-main-black border-2 border-main-black rounded-3xl px-6 py-1 text-base w-full md:text-lg disabled:bg-[#D5D5D5] disabled:border-[#D5D5D5] disabled:text-[#6F6F6F] disabled:hover:scale-100 hover:bg-[#4a4a4a]"
      >
        <span class="font-light">Go Back To Website</span>
      </button>
    </div>
  </div>
</div>
