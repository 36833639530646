import { Injectable } from '@angular/core';
import { AdminRole, MerchantRole } from '../shared/enums/roles';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISetMechantForm } from '../shared/interfaces/form.interface';
import { Bank, BankList } from '../shared/interfaces/bank.interface';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private readonly merchantRoles: MerchantRole[] = [
    MerchantRole.ADMIN,
    MerchantRole.STAFF,
  ];
  private readonly adminRoles: AdminRole[] = [
    AdminRole.BACKOFFICE_ADMIN,
    AdminRole.SUPERADMIN,
  ];

  private merchantSetUpSubject: BehaviorSubject<ISetMechantForm | null> =
    new BehaviorSubject<ISetMechantForm | null>(null);

  constructor() {}

  private today = new Date();
  private month = this.today.getMonth() + 1;
  private year = this.today.getFullYear();

  public get merchantSetUpValue() {
    return this.merchantSetUpSubject.value;
  }

  public get merchantSetUpObservable(): Observable<any> {
    return this.merchantSetUpSubject.asObservable();
  }

  public setMechantData(merchantData: any): void {
    this.merchantSetUpSubject.next(merchantData);
  }

  // 1st day of year
  get dateStart() {
    return new Date(this.year, this.month - 1, 1);
  }
  // last day of year
  get dateEnd() {
    return new Date(this.year, this.month, 0);
  }
  // year
  get thisYear() {
    return this.year;
  }

  isMerchant(roles: AdminRole | MerchantRole): boolean {
    if (roles && roles in MerchantRole) {
      return this.merchantRoles.includes(roles as MerchantRole);
    }
    return false;
  }
  isAdmin(roles: AdminRole | MerchantRole): boolean {
    if (roles && roles in AdminRole) {
      return this.adminRoles.includes(roles as AdminRole);
    }
    return false;
  }

  convertDate(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    const result = `${year}-${month}-${day}`;
    return result;
  }

  // constant sportType

  public sportType: { view: string; viewValue: string }[] = [
    { view: 'Basket Ball', viewValue: 'basketball' },
    { view: 'Football', viewValue: 'football' },
    { view: 'Tennis', viewValue: 'tennis' },
    { view: 'Padel Tennis', viewValue: 'padel tennis' },
  ];

  // constant profile color
  public colorOptions = [
    '#000000',
    '#03346E',
    '#6EACDA',
    '#3DC2EC',
    '#D21312',
    '#ED2B2A',
    '#F15A59',
    '#1E5128',
    '#4E9F3D',
    '#D8E9A8',
    '#1B4242',
    '#5C8374',
    '#9EC8B9',
    '#3C2A21',
    '#D5CEA3',
    '#E5E5CB',
    '#7A1CAC',
    '#AD49E1',
    '#EBD3F8',
    '#FFF78A',
    '#FFE382',
    '#FFC47E',
    '#FFAD84',
    '#FF9843',
    '#472D2D',
    '#553939',
    '#704F4F',
    '#A77979',
    '#EEEEEE',
  ];

  // constant month

  public months = [
    {
      view: 'All month',
      viewValue: -1,
    },
    {
      view: 'January',
      viewValue: 0,
    },
    {
      view: 'Febuary',
      viewValue: 1,
    },
    {
      view: 'March',
      viewValue: 2,
    },
    {
      view: 'April',
      viewValue: 3,
    },
    {
      view: 'May',
      viewValue: 4,
    },
    {
      view: 'Jun',
      viewValue: 5,
    },
    {
      view: 'July',
      viewValue: 6,
    },
    {
      view: 'August',
      viewValue: 7,
    },
    {
      view: 'September',
      viewValue: 8,
    },
    {
      view: 'October',
      viewValue: 9,
    },
    {
      view: 'November',
      viewValue: 10,
    },
    {
      view: 'December',
      viewValue: 11,
    },
  ];

  public getBankInfo(bank: string) {
    const bankInfo = this.bankList.find((item: Bank) => item.symbol === bank);
    if (bankInfo) {
      return bankInfo;
    } else {
      return null;
    }
  }

  // constant banks
  public bankList: Bank[] = [
    {
      name: 'กสิกรไทย',
      fullname: 'ธนาคารกสิกรไทย',
      nameEN: 'Kasikorn Bank',
      symbol: 'KBANK',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/KBANK.png',
      color: '#1DA858',
    },
    {
      name: 'ไทยพาณิชย์',
      fullname: 'ธนาคารไทยพาณิชย์',
      nameEN: 'The Siam Commercial Bank',
      symbol: 'SCB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/SCB.png',
      color: '#543186',
    },
    {
      name: 'กรุงไทย',
      fullname: 'ธนาคารกรุงไทย',
      nameEN: 'Krungthai Bank',
      symbol: 'KTB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/KTB.png',
      color: '#1DA8E6',
    },
    {
      name: 'กรุงเทพ',
      fullname: 'ธนาคารกรุงเทพ',
      nameEN: 'Bangkok Bank',
      symbol: 'BBL',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/BBL.png',
      color: '#29449D',
    },
    {
      name: 'กรุงศรีอยุธยา',
      fullname: 'ธนาคารกรุงศรีอยุธยา',
      nameEN: 'Krungsri Bank',
      symbol: 'BAY',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/BAY.png',
      color: '#FFD51C',
    },
    {
      name: 'ทีเอ็มบีธนชาต',
      fullname: 'ธนาคารทีเอ็มบีธนชาต',
      nameEN: 'TMBThanachart Bank',
      symbol: 'TTB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/TTB.png',
      color: '#0C55F2',
    },
    {
      name: 'ยูโอบี',
      fullname: 'ธนาคารยูโอบี',
      nameEN: 'United Overseas Bank',
      symbol: 'UOB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/UOB.png',
      color: '#E41A26',
    },
    {
      name: 'เกียรตินาคิน',
      fullname: 'ธนาคารเกียรตินาคินภัทร',
      nameEN: 'Kiatnakin Phatra Bank',
      symbol: 'KKP',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/KKP.png',
      color: '#5A547C',
    },
    {
      name: 'ออมสิน',
      fullname: 'ธนาคารออมสิน',
      nameEN: 'Government Savings Bank',
      symbol: 'GSB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/GSB.png',
      color: '#ED1891',
    },
    {
      name: 'ธ.ก.ส.',
      fullname: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
      nameEN: 'Bank for Agriculture and Agricultural Cooperatives',
      symbol: 'BAAC',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/BAAC.png',
      color: '#CCA41C',
    },
    {
      name: 'ซีไอเอ็มบี',
      fullname: 'ธนาคารซีไอเอ็มบี',
      nameEN: 'CIMB Thai Bank',
      symbol: 'CIMB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/CIMB.png',
      color: '#BD1325',
    },
    {
      name: 'ซิตี้แบงก์',
      fullname: 'ธนาคารซิตี้แบงก์',
      nameEN: 'citibank',
      symbol: 'CITI',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/CITI.png',
      color: '#0F3D89',
    },
    {
      name: 'ธ.อ.ส.',
      fullname: 'ธนาคารอาคารสงเคราะห์',
      nameEN: 'GH Bank',
      symbol: 'GHB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/GHB.png',
      color: '#FF8614',
    },
    {
      name: 'เอชเอสบีซี',
      fullname: 'ธนาคารเอชเอสบีซี',
      nameEN: 'HSBC Bank',
      symbol: 'HSBC',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/HSBC.png',
      color: '#FF1518',
    },
    {
      name: 'อิสลามแห่งประเทศไทย',
      fullname: 'ธนาคารอิสลามแห่งประเทศไทย',
      nameEN: 'Islamic Bank of Thailand',
      symbol: 'IBANK',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/IBANK.png',
      color: '#164626',
    },
    {
      name: 'ไอซีบีซี',
      fullname: 'ธนาคารไอซีบีซี',
      nameEN: 'ICBC Thai Commercial Bank',
      symbol: 'ICBC',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/ICBC.png',
      color: '#CD1511',
    },
    {
      name: 'แลนด์ แอนด์ เฮ้าส์',
      fullname: 'ธนาคารแลนด์ แอนด์ เฮ้าส์',
      nameEN: 'LH Bank',
      symbol: 'LHB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/LHB.png',
      color: '#727375',
    },
    {
      name: 'ไทยเครดิต',
      fullname: 'ธนาคารไทยเครดิต',
      nameEN: 'Thai Credit Bank',
      symbol: 'TCRB',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/TCRB.png',
      color: '#FF7813',
    },
    {
      name: 'ทิสโก้',
      fullname: 'ธนาคารทิสโก้',
      nameEN: 'Tisco Bank',
      symbol: 'TISCO',
      icon: 'https://raw.githubusercontent.com/casperstack/thai-banks-logo/master/icons/TISCO.png',
      color: '#267CBC',
    },
  ];
}
