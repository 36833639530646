import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAuthFeatureState } from './auth.states';
import {
  initAuth,
  loadAuth,
  login,
  loginFailed,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  setIsFirstTime,
  storedAuth,
  updateRoles,
  updateToken,
} from './auth.action';
import { catchError, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectStoredAuth } from './auth.selectors';
import { AuthService } from 'src/app/services/auth.service';
import { setUserInfo } from '../user/user.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<{ auth: IAuthFeatureState }>,
    private authService: AuthService,
    private router: Router
  ) {}

  loadAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAuth),
      switchMap(() => {
        const storedAuth = localStorage.getItem('auth');

        if (storedAuth) {
          const auth = JSON.parse(storedAuth);
          if (auth.accessToken && auth.refreshToken) {
            return of(loadAuth({ currentUser: auth }));
          }
        }
        return of(loadAuth({ currentUser: null }));
      })
    )
  );
  storedAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          setIsFirstTime,
          loginSuccess,
          logoutSuccess,
          updateToken,
          updateRoles
        ),
        withLatestFrom(this.store.select(selectStoredAuth)),
        tap(([action, auth]) => {
          if (auth) {
            localStorage.setItem('auth', JSON.stringify(auth));
          } else {
            localStorage.removeItem('auth');
          }
        })
      ),
    { dispatch: false }
  );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action) => {
        return this.authService.login(action.login).pipe(
          switchMap((item: any) => [
            loginSuccess({ currentUser: item.data }),
            // storedAuth(),
            // UserActions.loadUserInfo({user: item})
          ]),
          catchError((error: any) =>
            of(loginFailed({ error: error.statusMessage }))
          )
        );
      })
    )
  );

  // logout$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(logoutRequest),
  //     // exhaustMap((action) => this.userService.logout()),
  //     switchMap((action) => {
  //       return this.authService.logout().pipe(
  //         switchMap((item: any) => [
  //           // AuthUserActions.loginSuccess({item}),
  //           logoutSuccess(),
  //           setUserInfo({ user: null }),
  //         ]),
  //         catchError((error: any) =>
  //           of(logoutFailure({ error: error.statusMessage }))
  //         )
  //       );
  //     })
  //   )
  // );

  // logoutSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(logoutSuccess),
  //       tap((item: any) => {
  //         localStorage.removeItem('profile');
  //         localStorage.removeItem('auth');
  //         this.router.navigate(['']);
  //       })
  //     ),
  //   { dispatch: false }
  // );
}
