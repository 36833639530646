import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAuthFeatureState } from './_store/auth/auth.states';

import { initUser } from './_store/user/user.actions';
import { AppState } from './shared/interfaces/utils.interface';
import { initAuth } from './_store/auth/auth.action';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Yarrow_Liff_backoffice_FE';
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(initAuth());
    this.store.dispatch(initUser());
  }
}
