import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, IAuth, IAuthFeatureState } from './auth.states';
import { IToken } from 'src/app/shared/interfaces/utils.interface';

const selectAuthFeature =
  createFeatureSelector<IAuthFeatureState>(authFeatureKey);

//storedAuth
export const selectStoredAuth = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => {
    // const { ...accessToken } = state.user;
    return state.user;
  }
);
//isAuthenticated
export const selectIsAuthenticated = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => state.isAuthenticate
);
//auth states
export const selectAuthState = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => state
);
//auth roles
export const selectAuthRole = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => (state.user ? state.user.roles : null)
);

//get token
export const selectGetToken = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => {
    const token: IToken = {
      accessToken: null,
      refreshToken: null,
    };
    const accesToken = state.user?.accessToken;
    const refreshToken = state.user?.refreshToken;

    if (accesToken && refreshToken) {
      token.accessToken = accesToken;
      token.refreshToken = refreshToken;
    }

    return token;
  }
);

export const selectError = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => state.error
);
export const selectIsLoading = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => state.isLoading
);
export const selectAuthSuccess = createSelector(
  selectAuthFeature,
  (state: IAuthFeatureState) => state.isSuccess
);
