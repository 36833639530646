import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';

import {
  catchError,
  exhaustMap,
  filter,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  selectAuthRole,
  selectIsAuthenticated,
} from '../_store/auth/auth.selectors';
import { AdminRole, MerchantRole } from '../shared/enums/roles';
import { AuthService } from './auth.service';
import { logoutSuccess } from '../_store/auth/auth.action';

import { AppState } from '../shared/interfaces/utils.interface';
import { setUserInfo } from '../_store/user/user.actions';
import { HelperService } from './helper.service';

@Injectable()
export class PermissionsService {
  private destroy$ = new Subject<void>();
  isAuthenticated$!: Observable<boolean>;
  authRole$!: Observable<AdminRole | MerchantRole | null>;
  // userProfile!: Observable<IUserProfile>
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private helperService: HelperService
  ) {
    this.isAuthenticated$ = store.select(selectIsAuthenticated);
    this.authRole$ = store.select(selectAuthRole);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  canActivate(): Observable<boolean> {
    return this.isAuthenticated$.pipe(
      filter(Boolean),
      takeUntil(this.destroy$),
      tap((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.navigate(['']);
        }
      }),
      catchError(() => {
        console.error('Error checking authentication');
        this.router.navigate(['']);
        return of(false);
      })
    );
  }

  canActivateRoleAdmin(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authRole$.pipe(
      exhaustMap((data) => {
        if (data && this.helperService.isAdmin(data)) {
          return of(true);
        } else {
          this.store.dispatch(setUserInfo({ user: null }));
          this.store.dispatch(logoutSuccess());
          localStorage.removeItem('profile');
          localStorage.removeItem('auth');
          this.router.navigate(['']);
          return of(false);
        }
      }),
      takeUntil(this.destroy$)
    );
  }
  canActivateRoleMerchant(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authRole$.pipe(
      exhaustMap((data) => {
        if (data && this.helperService.isMerchant(data)) {
          return of(true);
        } else {
          this.store.dispatch(setUserInfo({ user: null }));
          this.store.dispatch(logoutSuccess());
          localStorage.removeItem('profile');
          localStorage.removeItem('auth');
          this.router.navigate(['']);
          return of(false);
        }
      }),
      takeUntil(this.destroy$)
    );
  }
}

export const authGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};
export const authGuardChild: CanActivateChildFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};

export const authAdminRoleGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivateRoleAdmin(route);
};
export const authMerchantRoleGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivateRoleMerchant(route);
};
