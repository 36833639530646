//merchant
export enum MerchantRole {
  STAFF = 'STAFF',
  ADMIN = 'ADMIN',
}

//admin
export enum AdminRole {
  BACKOFFICE_ADMIN = 'BACKOFFICE_ADMIN',
  SUPERADMIN = 'SUPERADMIN',
}
