import { createAction, emptyProps, props } from '@ngrx/store';
import { IUserProfile } from './user.states';

export const initUser = createAction('[USER] InitUser', emptyProps);

export const setUserInfo = createAction(
  '[USER] Load user information',
  props<{ user: IUserProfile | null }>()
);
export const setUserIsInitMerchant = createAction(
  '[USER] Set User IsInitMerchant',
  props<{ isInitMerchant: boolean }>()
);
