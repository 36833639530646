import { createAction, emptyProps, props } from '@ngrx/store';
import { ILogin, IToken } from 'src/app/shared/interfaces/utils.interface';
import { IAuth } from './auth.states';
import { AdminRole, MerchantRole } from 'src/app/shared/enums/roles';

export const login = createAction('[Auth] Login', props<{ login: ILogin }>());

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ currentUser: IAuth }>()
);

export const initAuth = createAction('[Auth] Init Auth', emptyProps);
export const loadAuth = createAction(
  '[Auth] Load Auth',
  props<{ currentUser: IAuth | null }>()
);

export const setIsFirstTime = createAction(
  '[Auth] Set IsFirstTime',
  props<{ isFirstTime: boolean }>()
);

export const storedAuth = createAction('[Auth] Stored Auth', emptyProps);

export const loginFailed = createAction(
  '[Auth] Login Failed',
  props<{ error: string | null }>()
);

// export const logoutRequest = createAction(
//   '[Auth User] Notify server to logout',
//   emptyProps
// );
export const logoutSuccess = createAction(
  '[Auth User] Notify server to logout',
  emptyProps
);

export const logoutFailure = createAction(
  '[Auth User] Logout FAILURE',
  props<{ error: string | null }>()
);
export const updateToken = createAction(
  '[Auth User] Update Token',
  props<{ token: IToken }>()
);
export const updateRoles = createAction(
  '[Auth User] Update Roles',
  props<{ roles: AdminRole | MerchantRole }>()
);
