import { createAction, emptyProps, props } from '@ngrx/store';
import { Noti } from './noti.states';
import { INoti } from './noti.states';

export const init = createAction('[Noti] Init', emptyProps);

export const set = createAction('[Noti] Set', props<{ noti: INoti }>());
export const increment = createAction(
  '[Noti] Increment',
  props<{ value: Noti }>()
);
export const decrement = createAction(
  '[Noti] Decrement',
  props<{ value: Noti }>()
);
