import { AdminRole, MerchantRole } from 'src/app/shared/enums/roles';

export interface IUserProfile {
  _id: string;
  image: string | null;
  isInitMerchant: boolean;
  name: string;
  email: string;
  roles: AdminRole | MerchantRole;
  phoneNumber?: number;
  merchant?: IMerchantInfo;
}

export interface IMerchantInfo {
  _id: string;
  latitude: number;
  longitude: number;
  merchantName: string;
  sportType: string[];
  accountBank: string;
  accountName: string;
  accountNo: string;
  merchantColor: string;
  merchantMapUrl: string;
}

export interface IUserFeatureState {
  profile: IUserProfile | null;
  isSuccess: boolean;
  error: string | null;
}

export const userFeatureKey = 'user';
