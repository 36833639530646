import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  authAdminRoleGuard,
  authGuard,
  authMerchantRoleGuard,
} from './services/auth.guard';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'merchant',
    loadChildren: () =>
      import('./merchant/merchant.module').then((m) => m.MerchantModule),
    canActivate: [authGuard, authMerchantRoleGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuard, authAdminRoleGuard],
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
